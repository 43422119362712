import { CFV_WITH_OPTIONS } from '@/storychief/constants/Constants';

/**
 * This function takes the values from CustomFields.jsx and converts them in a format for GraphQL.
 *
 * @param {Array} customFields
 * @param {Array} customFieldValues
 * @returns {Array}
 */
export default function getUpdatedCustomFieldValues(customFields, customFieldValues) {
  return customFields.map((customField) => {
    const fieldValue = customFieldValues.find((cfv) => cfv.key === customField.name);

    if (CFV_WITH_OPTIONS.includes(customField.type)) {
      if (customField.option_src) {
        return {
          custom_field_id: customField.id,
          dynamic_options: (fieldValue?.value || []).map((v) => ({
            value: v.value,
            label: v.label,
          })),
        };
      }

      const checked = (fieldValue?.value || []).map((v) => v.value);

      return {
        custom_field_id: customField.id,
        options: customField.options.filter((o) => checked.includes(o.value)).map((o) => o.id),
      };
    }

    return {
      custom_field_id: customField.id,
      plain_value: fieldValue?.value || null,
    };
  });
}
